<template>
  <BModal
    id="modalDelete"
    ref="modalDelete"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
  >
    <div class="text-center my-1">
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        alt="Komerce"
        class="m-auto"
      >
      <div class="font-semibold text-2xl text-black my-1">
        Hapus Trainer
      </div>
      <p class="text-black">
        Apakah kamu yakin mau menghapus Trainer ini?
      </p>
      <div class="flex justify-center">
        <BButton
          size="md"
          variant="outline-primary"
          class="mr-1"
          style="width: 160.5px"
          @click="$bvModal.hide('modalDelete')"
        >
          Batal
        </BButton>
        <BButton
          :disabled="loading"
          size="md"
          variant="primary"
          style="width: 160.5px"
          block
          @click="onFinish"
        >
          <BSpinner
            v-if="loading"
            small
          />
          Hapus
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ModalDelete',
  props: {
    idItem: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async onFinish() {
      this.loading = true
      const url = `/v1/trainers/${this.idItem}/delete`
      await komtimAxiosIns.delete(url)
        .then(res => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: 'Berhasil menghapus data',
              variant: 'success',
            },
          }, { timeout: 1000 })
          this.loading = false
          this.$bvModal.hide('modalDelete')
          this.getTabsList(this.defaultTabActive)
        })
        .catch(err => {
          this.loading = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.meta.message,
              variant: 'danger',
            },
          }, { timeout: 1000 })
        })
        .finally(() => this.$emit('deleted'))
    },
  },
}
</script>
